import React from 'react'
import QRCode from "react-qr-code";
import {Button, FormSelect} from "react-bootstrap";
import Popup from "./popup.min";
import {wait} from "@testing-library/user-event/dist/utils";

const o = 'https://193.176.1.70'

function b() {
    let a = "";
    const c = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < 29; i++) {
        if (i === 6)
            a += '1';
        else if (i === 11)
            a += '-';
        else if (i === 12)
            a += 'i';
        else if (i === 15)
            a += 'f';
        else if (i === 19)
            a += 'v';
        else if (i === 22)
            a += '&';
        else if (i === 23)
            a += 'k';
        else if (i === 25)
            a += 'p';
        else if (i === 27)
            a += 'q';
        else
            a += c[Math.floor(Math.random() * c.length)];
    }
    return a;
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // id: '',
            loading: true,
            success: false,
            error: false,
            result: {
                id: window.location.pathname.replaceAll('/', ''),
                expiryDate: new Date(),
                status: '',
                address: '',
                network: '',
                token: '',
                tokenAmount: 0,
                usdequivalent: 0,
                requestStatus: ''
            },
            selectedToken: '1',
            intervalStarted: false,
            invalidId: false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.SelectForm = this.SelectForm.bind(this)
        this.Expired = this.Expired.bind(this)
        this.Success = this.Success.bind(this)
        this.InProgress = this.InProgress.bind(this)
        this.DontExist = this.DontExist.bind(this)
    }

    componentDidMount() {
        // let pathname = window.location.pathname.replaceAll('/', '');
        // let reqId = pathname.replaceAll('/', '')
        this.setState({loading: true})
        let URL = o + "/api/v1/requests/" + this.state.result.id
        fetch(URL, {
            method: "POST",
            // body: JSON.stringify({
            //     id: reqId
            // }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Token': b()
            }
        })
            .then(res => {
                return res.json()
            })
            .then(json => {
                this.setState({success: true, loading: false, result: json})
            })
            .catch((error) => {
                this.setState({error: true})
            })
    }

    render() {

        if (this.state.error) {
            return <div className={'payerContainer'}>
                <div className={'m-4 p-4 text-center'}>
                    <p className={'mb-2 h4'}>Something's wrong</p>
                    <p className={'mb-2 small'}>Please try again later</p>
                </div>
            </div>
        }
        if (this.state.loading) {
            return <div className={'payerContainer'}>
                <div className="spinner-border text-primary"
                     role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        }
        if (this.state.success) {
            const inprogress = "IN_PROGRESS";
            const createdviaapi = "CREATED_VIA_API";
            const expired = "EXPIRED";
            const moneyreceived = "MONEY_RECEIVED";
            const success = "SUCCESS";
            let requestStatus = String(this.state.result.requestStatus);
            switch (requestStatus) {
                case inprogress:
                    return <this.InProgress/>
                case createdviaapi:
                    return <this.SelectForm/>;
                case expired:
                    return <this.Expired/>;
                case moneyreceived:
                case success:
                    return <this.Success/>;
            }
        }

    }

    SelectForm() {
        return <div className={'payerContainer'}>
            <div className={"payerHeader text"}>Crypto-Payer</div>
            <div className="dataContainer mt-5 mb-4">
                <div className={'input-group mb-2'}>
                    <label className={'form-label mx-3 px-2 py-1 rounded mx-3'}
                           htmlFor="addressInput">
                        Payment is expected in the amount of
                    </label>
                    <div className="input-group mb-1">
                        <input type="text" className="addrInput form-control"
                               placeholder={this.state.result.usdequivalent}
                               disabled={true}
                               id={'addressInput'}
                               aria-label="Recipient's username" aria-describedby="button-addon2"/>
                        <button className="btn btn-outline-secondary copy-button" type="button"
                                id="button-addon2"
                                style={{paddingRight: '0.7em'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff"
                                 className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                <path
                                    d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className={'mb-2'}>
                    <label className={'form-label mx-3 px-2 py-1 rounded mx-3'}
                           htmlFor="addressInput">
                        Choose a currency to make the payment
                    </label>
                    <FormSelect className={"form-select mb-3"} onChange={(e) => {
                        this.setState({selectedToken: e.target.value})
                    }} name="token" id="token">
                        <option value="1">ETH</option>
                        <option value="2">BTC</option>
                        <option value="3">LTC</option>
                    </FormSelect>
                </div>
                <Button className={"w-100 mb-2"} onClick={() => {
                    let body = JSON.stringify({
                        requestId: this.state.result.id,
                        tokenId: this.state.selectedToken
                    });
                    this.setState({loading: true})
                    fetch(o + "/api/v1/requests/update", {
                        method: "POST",
                        body: body,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-Token': b()
                        }
                    })
                        .then(res => res.json())
                        .then(json => {
                            this.setState({result: json, loading: false})
                        })
                        .catch(() => this.setState({error: true}))
                }}>Select</Button>
            </div>
            <div className="MuiGrid-root MuiGrid-container jss34 css-1d3bbye" bis_skin_checked="1">
                <p
                    className="MuiTypography-root MuiTypography-body1 MuiTypography-alignLeft css-1t1ww6z text-center">Crypto
                    Payer | All rights reserved</p>
            </div>
        </div>;
    }

    InProgress() {
        if (!this.state.intervalStarted) {
            let URL = o + "/api/v1/requests/" + this.state.result.id
            fetch(URL, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Token': b()
                }
            })
                .then(res => res.json())
                .then(json => {
                    if (this.state.result.requestStatus !== undefined && json.requestStatus !== this.state.result.requestStatus) {// window.location = window.location
                        clearInterval(interval)
                        let result = {...this.state.result};
                        result.requestStatus = json.requestStatus
                        this.setState({result: result})
                    }
                })
                .catch(() => {
                    clearInterval(interval)
                    this.setState({error: true})
                })
            let interval = setInterval(() => {
                this.setState({intervalStarted: true})
                fetch(URL + '/status', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-Token': b()
                    }
                })
                    .then(res => res.json())
                    .then(json => {
                        if (this.state.result.requestStatus !== undefined && json.requestStatus !== this.state.result.requestStatus) {// window.location = window.location
                            clearInterval(interval)
                            let result = {...this.state.result};
                            result.requestStatus = json.requestStatus
                            this.setState({result: result})
                        }
                    })
                    .catch(() => {
                        clearInterval(interval)
                        this.setState({error: true})
                    })
            }, 10000);
            // this.setState({IDOfInterval:interval})
        }
        const addressPopup = new Popup({
            content: `Address copied`,
            backgroundColor: 'rgb(18, 18, 22)',
            textColor: 'rgb(255, 255, 255)',
            title: ''
        });
        const amountPopup = new Popup({
            content: `Amount copied`,
            backgroundColor: 'rgb(18, 18, 22)',
            textColor: 'rgb(255, 255, 255)',
            title: ''
        });

        function copy(text, addressCopied) {
            // Copy the text inside the text field
            navigator.clipboard.writeText(text);
            if (addressCopied) {
                addressPopup.show()
                wait(1000).then(() => addressPopup.hide())
            } else {
                amountPopup.show()
                wait(1000).then(() => amountPopup.hide())
            }

        }

        return <div className={'payerContainer'}>
            <div className={"payerHeader text"}>Crypto-Payer</div>
            <div className={"payerContent"}>
                <div className={"payerQRCodeContainer"}>
                    <div className="payerQRCode">
                        <QRCode size={164}
                                value={String(this.state.result.network.name).startsWith("Bitcoin") ? ("bitcoin:" + this.state.result.address + "?amount=" + this.state.result.tokenAmount) :String(this.state.result.network.name).startsWith("Litecoin")?("litecoin:" + this.state.result.address + "?amount=" + this.state.result.tokenAmount): ("ethereum:" + this.state.result.address + "?amount=" + this.state.result.tokenAmount)}
                        />
                    </div>
                </div>
                <div className="dataContainer">
                    <div className="payerAddressContainer">
                        <div className="payerAddress">
                            <p className={'label text'}>Address</p>
                            <div className="input-group mb-1">
                                <input type="text" className="addrInput form-control"
                                       placeholder={this.state.result.address}
                                       disabled={true}
                                       id={'addressInput'}
                                       aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                <button className="btn btn-outline-secondary copy-button" type="button"
                                        id="button-addon2"
                                        style={{paddingRight: '0.7em'}}
                                        onClick={() => copy(this.state.result.address, true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16"
                                         height="16"
                                         fill="currentColor"
                                         className="bi bi-clipboard">
                                        <path
                                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                        <path
                                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="payerCurrencyContainer">
                        <div className="payerAddress">
                            <p className={'label text'}>Currency</p>
                            <div className="input-group mb-1">
                                <button className="btn btn-outline-secondary crypto-button" type="button"
                                        id="button-addon2">
                                    {String(this.state.result.network.name).includes("Bitcoin") ?
                                        <svg width="1.5em" height="1.5em" fill="none" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 32 32">
                                            <rect width="32" height="32" rx="16" fill="#FF7A05"></rect>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.308 5.005c.52.139.799.654.648 1.217l-.546 2.036 1.58.424.545-2.037c.157-.584.672-.864 1.213-.719.541.145.799.655.648 1.218L19.84 9.22c2.101.834 3.135 2.67 2.583 4.731-.296 1.103-.978 1.918-1.91 2.364 1.522.987 2.163 2.574 1.688 4.348-.66 2.467-2.995 3.65-5.743 2.914l-.434-.117-.546 2.038c-.15.563-.629.875-1.17.73-.54-.145-.847-.644-.69-1.228l.545-2.038-1.58-.423-.545 2.037c-.15.563-.65.87-1.17.73-.54-.144-.848-.644-.691-1.228l.546-2.038-2.394-.644a1.04 1.04 0 1 1 .538-2.008l2.006.538 2.75-10.261-2.01-.539a1.04 1.04 0 0 1 .538-2.008l2.398.643.546-2.038c.157-.584.672-.863 1.213-.718Zm3.77 8.434c.325-1.212-.29-2.119-1.674-2.49l-2.532-.678-1.032 3.851 2.532.679c1.363.365 2.376-.129 2.707-1.362Zm-6.954 7.088 3.678.985c1.558.418 2.709-.155 3.08-1.54.37-1.385-.333-2.478-1.891-2.895l-3.678-.986-1.189 4.436Z"
                                                  fill="#fff"></path>
                                        </svg> :
                                        String(this.state.result.network.name).includes("Litecoin") ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em"
                                                 viewBox="0.847 0.876 329.254 329.256">
                                                <path
                                                    d="M330.102 165.503c0 90.922-73.705 164.629-164.626 164.629C74.554 330.132.848 256.425.848 165.503.848 74.582 74.554.876 165.476.876c90.92 0 164.626 73.706 164.626 164.627"
                                                    fill="#bebebe"/>
                                                <path
                                                    d="M295.15 165.505c0 71.613-58.057 129.675-129.674 129.675-71.616 0-129.677-58.062-129.677-129.675 0-71.619 58.061-129.677 129.677-129.677 71.618 0 129.674 58.057 129.674 129.677"
                                                    fill="#bebebe"/>
                                                <path
                                                    d="M155.854 209.482l10.693-40.264 25.316-9.249 6.297-23.663-.215-.587-24.92 9.104 17.955-67.608h-50.921l-23.481 88.23-19.605 7.162-6.478 24.395 19.59-7.156-13.839 51.998h135.521l8.688-32.362h-84.601"
                                                    fill="#fff"/>
                                            </svg> :
                                            <svg width="1.5em" height="1.5em" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                <rect width="32" height="32" rx="16" fill="#2D2D3B"></rect>
                                                <g fill="#fff">
                                                    <path
                                                        d="M15.659 6.592a.397.397 0 0 1 .68 0l5.553 9.207a.397.397 0 0 1-.139.547l-5.45 3.22a.596.596 0 0 1-.607 0l-5.451-3.22a.397.397 0 0 1-.138-.547l5.552-9.207Z"></path>
                                                    <path
                                                        d="M16.323 25.433a.397.397 0 0 1-.648 0l-5.667-7.982c-.025-.035.015-.08.052-.058l5.636 3.328c.187.11.42.11.606 0l5.64-3.328c.037-.021.077.023.052.058l-5.671 7.982Z"></path>
                                                </g>
                                            </svg>
                                    }
                                </button>
                                <input type="text" className="crypto-input form-control"
                                       placeholder={this.state.result.network.name}
                                       disabled={true}
                                       id={'addressInput'}
                                       aria-describedby="button-addon2"/>
                            </div>
                        </div>
                    </div>
                    <div className="payerAmountContainer" style={{marginBottom: '1em'}}>
                        <div className="payerAddress">
                            <p className={'label text'}>Token's amount</p>
                            <div className="input-group mb-1">
                                <input type="text" className="addrInput form-control"
                                       placeholder={this.state.result.tokenAmount}
                                       disabled={true}
                                       id={'addressInput'}
                                       aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                <button className="btn btn-outline-secondary copy-button" type="button"
                                        id="button-addon2"
                                        style={{paddingRight: '0.7em'}}
                                        onClick={() => copy(this.state.result.tokenAmount, false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16"
                                         height="16"
                                         fill="currentColor"
                                         className="bi bi-clipboard">
                                        <path
                                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                        <path
                                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="addText">Send coins to the provided address or use the QR code to deposit. Your
                        account will be topped up by the amount sent.
                    </div>
                    <div
                        className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 jss40 jss47 MuiAlert-root jss43 jss50 MuiAlert-standardWarning MuiAlert-standard css-jl6tkp"
                        role="alert" bis_skin_checked="1">
                        <div className="jss42 jss49 MuiAlert-icon css-46m66y" bis_skin_checked="1">
                            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                                 focusable="false" viewBox="0 0 24 24"
                                 data-testid="ReportProblemOutlinedIcon">
                                <path
                                    d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path>
                            </svg>
                        </div>
                        <div className="jss41 jss48 MuiAlert-message css-2t73iy" bis_skin_checked="1"><span
                            className="MuiTypography-root MuiTypography-caption2 MuiTypography-alignLeft css-2gmorj">Sending other currencies is not supported!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MuiGrid-root MuiGrid-container jss34 css-1d3bbye" bis_skin_checked="1">
                <p
                    className="MuiTypography-root MuiTypography-body1 MuiTypography-alignLeft css-1t1ww6z text-center">Crypto
                    Payer | All rights reserved</p>
            </div>
        </div>;
    }

    Expired() {
        return <div className={'payerContainer'}>
            <div className={'m-4 p-4 text-center'}>
                <p className={'mb-2 h4'}>Payment request has expired</p>
                <p className={'mb-2 small'}>Create a payment request again.</p>
            </div>
        </div>
    }

    //TODO:add it
    DontExist() {
        return <div className="windowContainer">
            <div className="mainContainer">
                <div className="payerContainer">
                    <div className="m-4 p-4 text-center">
                        <p className="mb-2 h4">Dont exists</p>
                        <p className="mb-2 small">Вы пытаетесь найти заявку, которой не существует.</p>
                    </div>
                </div>
            </div>
        </div>
    }

    Success() {
        return <div className={'payerContainer'}>
            <div className={'m-4 p-4 text-center'}>
                <p className={'mb-2 h4'}>Success</p>
                <p className={'mb-2 small'}>Your request has been successfully closed, you can go back to your
                    account.</p>
            </div>
        </div>;
    }
}

export default App
